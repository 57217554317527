.club-map-run-container {
    border-radius: 8px;
    margin: 20px 0;
    display: flex;
    flex-direction: column; /* Ensure elements stack vertically */
    align-items: center; /* Center content */
  }

  
  .club-map-run-container iframe {
    width: 100%;
    height: 150px;
    max-height: 200px;
    border: 0;
    border-radius: 8px;
  }
  
.card-wrapper {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 350px;
  border-radius: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: transparent;
  margin: 0 auto;
  cursor: pointer;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
}

.mute-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  padding: 8px;
  font-size: 18px;
  border-radius: 50%;
  cursor: pointer;

  opacity: 0; /* Hide by default */
  transition: opacity 0.3s;
}

/* Styles for the play button overlay */
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  padding: 16px;
  font-size: 32px;
  border-radius: 50%;
  cursor: pointer;
}


.video-container:hover .mute-button {
  opacity: 1; /* Show on hover */
}

.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.detail-icon {
  margin-right: 8px;
}

.card-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  color: black;
  margin-right: 8px;
}

.card-subtitle {
  font-size: 1rem;
  padding-top: 0.5rem;
  font-weight: bold;
  text-align: left;
  color: black;
}

.card-description {
  font-size: 1rem;
  color: gray;
  text-align: left;
  padding-top: 0.5rem;
}

.card-details {
  flex-grow: 1;
}

.card-details p {
  display: flex;
  align-items: center;
  text-align: left;
  color: gray;
}

.card-details p span {
  padding-right: 0.5rem;
}

.card-buttons {
  margin-top: 2rem;
  text-align: center;
}

.card-button {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: teal;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}

.card-button:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Updated styles for media-video and img-holder */
.img-holder {
  position: relative;
  width: 100%;
  max-width: 100%;
  background-color: rgb(252, 252, 252);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  height: 475px;
  border-radius: 10px;
  overflow: hidden;
}

.media-video,
.img-holder img {
  width: 100%;
  border-radius: 10px;
  aspect-ratio: 16 / 9;
  height: 475px;
  display: block;
  object-fit: cover;
}

.club-skill {
  margin-top: 1rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  font-size: 0.75rem;
  border-radius: 10px;
  background-color: rgb(160, 236, 220);
}

.paid-free {
  margin-top: 1rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  font-size: 0.75rem;
  border-radius: 10px;
  background-color: rgb(160, 236, 220);
}

.club-skill-wrapper {
  display: flex;
}

.divider {
  border: 0.5px solid lightgray;
  margin-top: 1rem;
  width: 100%;
}

.club-details {
  text-align: left;
  padding-left: 0;
}

.club-detail {
  display: flex;
  align-items: center;
  margin-bottom: -16px;
}

.club-details p {
  font-size: 1rem;
  margin-left: 0;
  padding-left: 0;
  color: gray;
}

.see-more-button {
  padding-top: 1rem;
  text-align: left;
  font-size: 0.75rem;
  color: blue;
}

.see-more-button:hover {
  cursor: pointer;
}

.star-rating {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.star-icon {
  display: inline;
}

.inline-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.flex-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .card-wrapper {
    max-width: 300px;
  }

  .img-holder {
    height: 400px;
  }

  .media-video, .img-holder img {
    width: 100%;
    height: 400px;
    aspect-ratio: 16 / 9;
    object-fit: cover; /* Makes sure the whole image is visible, including text */
}
  .card-details {
    margin-top: 1rem;
  }

  .card-title {
    font-size: 1.25rem;
    padding-top: 0.5rem;
  }

  .card-description {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .card-buttons {
    margin-top: 1.5rem;
  }

    /* Mobile-specific adjustments for mute and play buttons */
    .mute-button {
      bottom: 5px;
      right: 5px;
      padding: 6px;
      font-size: 16px;
    }
  
    .play-button {
      padding: 12px;
      font-size: 24px;
    }
}
.help-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Roboto', sans-serif;
    border-radius: 10px;
  }
  
  .help-header {
    text-align: center;
    margin-bottom: 3rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid #e0e0e0;
  }
  
  .help-title {
    font-size: 3rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .help-subtitle {
    font-size: 1.5rem;
    color: #666;
  }
  
  .help-content {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
  
  .help-section {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
  }
  
  .help-section:hover {
    transform: translateY(-5px);
  }
  
  .section-title {
    font-size: 2rem;
    color: #2c3e50;
    margin-bottom: 1rem;
    border-bottom: 2px solid #3498db;
    padding-bottom: 0.5rem;
    display: inline-block;
  }
  
  .section-content {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #34495e;
  }
  
  .section-content p {
    margin-bottom: 1rem;
  }
  
  .section-content p:last-child {
    margin-bottom: 0;
  }
  
  .help-list {
    padding-left: 1.5rem;
    margin-top: 1rem;
  }
  
  .help-list li {
    margin-bottom: 0.5rem;
  }
  
  .help-link {
    color: #3498db;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .help-link:hover {
    color: #2980b9;
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .help-container {
      padding: 1rem;
    }
  
    .help-title {
      font-size: 2.5rem;
    }
  
    .help-subtitle {
      font-size: 1.2rem;
    }
  
    .section-title {
      font-size: 1.8rem;
    }
  
    .section-content {
      font-size: 1rem;
    }
  }
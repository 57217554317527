.share-club-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 16px;
    /* background: linear-gradient(135deg, #4A90E2 0%, #5CB3FF 100%); */
    color: rgb(0, 0, 0);
    border: 0;
    border-radius: 50px; 
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 16px;
    width: auto;
    min-width: 120px;
    max-width: 200px;
  }
  
  .share-club-button:hover {
    /* background: linear-gradient(135deg, #3A80D2 0%, #4CA3EF 100%); */
    transform: translateY(-2px);
  }
  
  .share-club-button:focus {
    outline: none;
    /* box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.5); */
  }
  
  .share-club-button svg {
    transition: transform 0.3s ease;
    flex-shrink: 0;
  }
  
  .share-club-button:hover svg {
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .share-club-button {
      padding: 8px 14px;
      font-size: 13px;
      min-width: 100px;
      max-width: 180px;
    }
  }
  
  @media (max-width: 500px) {
    .share-club-button {
      padding: 8px 12px;
      font-size: 12px;
      min-width: auto;
      max-width: none;
      width: auto;
    }
    
    .share-club-button svg {
      width: 16px;
      height: 16px;
    }
  
    .share-club-button span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  
  @media (max-width: 350px) {
    .share-club-button {
      padding: 6px 10px;
      font-size: 11px;
    }
    
    .share-club-button svg {
      width: 14px;
      height: 14px;
    }
  }
.host-container {
    display: flex;
    align-items: center;
    margin: 20px 0 10px;
  }
  
  .host-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .host-title {
    margin-left: 8px;
    font-size: 18px;
    font-weight: 600;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .host-container {
      margin: 15px 0 8px;
    }
  
    .host-image {
      width: 50px;
      height: 50px;
    }
  
    .host-title {
      font-size: 16px;
    }
  }
  
  @media (max-width: 500px) {
    .host-container {
      margin: 12px 0 6px;
    }
  
    .host-image {
      width: 40px;
      height: 40px;
    }
  
    .host-title {
      font-size: 14px;
      margin-left: 6px;
    }
  }
  
  @media (max-width: 350px) {
    .host-container {
      margin: 10px 0 5px;
    }
  
    .host-image {
      width: 35px;
      height: 35px;
    }
  
    .host-title {
      font-size: 12px;
      margin-left: 5px;
    }
  }
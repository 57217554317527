/* src/Components/BackButton.css */

.back-button-container {
    display: flex;
    justify-content: flex-start; /* Align the button to the left */
  }
  
  .back-button {
    background-color: transparent;
    padding: 12px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    border-radius: 1rem;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  
  .back-button:hover {
    background-color: #f0f0f0; /* Corrected color code, #ffffff was incorrect */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow on hover */
    transform: scale(1.02); /* Slightly bigger on hover */
  }
  
  .back-button:focus {
    outline: none; /* Remove the default focus outline */
  }
  
.verified-badge {
    display: inline-flex;
    align-items: center;
    background-color: #1DA1F2;
    color: white;
    padding: 4px;
    padding-right: 6px;
    border-radius: 9999px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    cursor: default;
  }
  
  .verified-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  
  .verified-text {
    margin-top: 1px;
  }
  
  /* Hover effect */
  .verified-badge:hover {
    background-color: #1991db;
  }
  
  /* Optional: Add a subtle animation on hover */
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }
  
  .verified-badge:hover {
    animation: pulse 0.3s ease-in-out;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .verified-badge {
      font-size: 12px;
      padding: 3px 6px;
    }
  
    .verified-icon {
      width: 14px;
      height: 14px;
    }
  }
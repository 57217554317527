body {
  background-color: rgb(255, 255, 255);
  color: #333333;
}

.club-profile {
  max-width: 1100px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
}

.join-button {
  width: 100%;
  padding: 8px 12px;
  font-size: 12px;
  margin-bottom: 40px;
}

.club-what-to-expect {
  margin-bottom: 2rem;
}

.club-what-to-expect h2 {
  margin-bottom: 1rem;
}

.club-header {
  margin-bottom: 40px;
}

.club-title {
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 10px;
}

.club-location {
  font-size: 20px;
}

.club-hero {
  margin-bottom: 20px;
}

.club-images {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.club-image {
  width: calc((100% - 30px) / 3); /* Each image takes one-third of the total width minus the gaps */
  aspect-ratio: 5/5;
  object-fit: cover;
  border-radius: 8px;
}


.join-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  padding: 10px 16px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(135deg, #4A90E2 0%, #5CB3FF 100%);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(74, 144, 226, 0.3);
  width: 40%;
}

.join-button svg {
  flex-shrink: 0;
}

.join-button span {
  flex-grow: 0;
  text-align: left;
  margin-right: auto;
}

.join-button span {
  flex-grow: 1;
  text-align: center;
}

.club-content {
  display: flex;
  gap: 40px;
  margin-top: 40px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 2rem;
}

.club-main, .club-sidebar {
  background-color: #FFFFFF;
  border-radius: 16px;
}

.club-main {
  flex: 2;
}

/* Styles for the Upcoming Events section */
.club-upcoming-events {
  margin-top: 30px;
  background-color: #FFFFFF;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.club-upcoming-events h2 {
  margin-bottom: 15px;
}

.event-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.event-details p {
  margin: 0;
  font-size: 16px;
  color: #555;
}

.event-details p strong {
  color: #000;
}

.event-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(135deg, #4A90E2 0%, #5CB3FF 100%);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(74, 144, 226, 0.3);
  margin-top: 20px;
}

.event-link:hover {
  background: linear-gradient(135deg, #3A80D2 0%, #4CA2FF 100%);
}

.club-sidebar {
  flex: 1;
}

h2, h3 {
  color: #1A1A1A;
  margin-top: 0;
}

.run-details h2 {
  color: #1A1A1A;
  margin-top: 10px;
  margin-bottom: 20px;
}

.club-profile ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0 0 30px 0;
}

.club-profile li {
  margin-bottom: 15px;
}

.club-detail-item, .run-day-item, .contact-detail-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.club-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3438AE;
  flex-shrink: 0;
}

.detail-label {
  font-weight: 600;
  margin-right: 10px;
  color: #555;
  flex-shrink: 0;
}

.detail-value {
  flex-grow: 1;
  word-break: break-word;
}

.contact-detail-item a {
  display: flex;
  align-items: center;
  color: #4A4AFF;
  text-decoration: none;
  font-size: 16px;
}

.run-days-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.run-day-card {
  background-color: #ffffff;
  padding: 4px;
}

.run-day-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: bold;
  color: #3438AE;
}

.run-day-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.run-info-item {
  display: flex;
  align-items: center;
}

.run-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #3438AE;
  width: 24px;
  height: 24px;
}

.feeds-and-reviews {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}

.club-feeds, .club-map {
  background-color: #FFFFFF;
  /* padding: 30px; */
  border-radius: 16px;
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05); */
  box-sizing: border-box;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  flex: 1;
  height: auto;
  max-width: calc(50% - 10px);
  margin-top: 10px;
}

.feed-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  flex-grow: 0;
  height: auto;
  width: 100%;
  box-sizing: border-box;
}

.instagram-feed,
.strava-feed {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  height: auto;
}

.view-route-button {
  display: inline-flex;
  align-items: center;
  margin-top: 4px;
  justify-content: center;
  padding: 8px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #4A90E2;
  background: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  margin-left: auto;
}

.view-route-button:hover {
  color: #3A80D2;
  text-decoration: underline;
}

.instagram-feed > *,
.strava-feed > * {
  max-width: 100%;
  width: 100%;
}

.instagram-feed img,
.strava-feed img {
  max-width: 100%;
  display: block;
}

.instagram-feed iframe.instagram-media {
  max-width: 100% !important;
  width: 100% !important;
  min-width: 0 !important;
  margin: 0 auto !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  border: none !important;
}

.what-people-say {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: auto;
  max-width: 600px;
  overflow-y: auto;
}

.map-container {
  border-radius: 8px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map-container iframe {
  width: 100%;
  height: 550px;
  max-height: 550px;
  border: 0;
  border-radius: 8px;
}
@media (max-width: 768px) {
  .club-content {
    flex-direction: column;
    gap: 0px;
  }

  .club-sidebar {
    order: -1;
  }

  .club-main, .club-sidebar, .club-feeds, .club-map {
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .club-detail-item, .run-day-item, .contact-detail-item {
    flex-wrap: wrap;
  }

  .detail-label {
    min-width: auto;
    flex-basis: auto;
  }

  .feeds-and-reviews {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  }

  .club-feeds, .club-map {
    height: auto;
    margin-top: 0;
  }

  .feed-container {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .instagram-feed,
  .strava-feed {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .instagram-feed iframe.instagram-media {
    width: 100% !important;
    max-width: 100% !important;
  }

  .what-people-say {
    height: auto;
    max-height: none;
    overflow-y: visible;
  }

  .run-day-card {
    padding: 12px;
  }

  .run-icon {
    font-size: 16px;
  }

  .map-container {
    margin: 10px 0;
  }

  .map-container iframe {
    height: 300px;
    max-height: 300px;
  }

  /* Ensure the run details appear after club content */
  .run-details {
    order: 1;
  }
}
@media (max-width: 768px) {
  .club-content {
    flex-direction: column;
  }

  /* Ensure the club main appears above the club sidebar */
  .club-main {
    order: -1; /* Move the main content to the top */
  }

  .club-sidebar {
    order: 1; /* Sidebar comes after the main content */
  }

  .club-main, .club-sidebar, .club-feeds, .club-map {
    padding: 20px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .club-detail-item, .run-day-item, .contact-detail-item {
    flex-wrap: wrap;
  }


  .detail-label {
    min-width: auto;
    flex-basis: auto;
  }

  .feeds-and-reviews {
    flex-direction: column;
    align-items: stretch;
    gap: 20px;
  }

  .club-feeds, .club-map {
    height: auto;
    margin-top: 0;
  }

  .feed-container {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .instagram-feed,
  .strava-feed {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }

  .instagram-feed iframe.instagram-media {
    width: 100% !important;
    max-width: 100% !important;
  }

  .what-people-say {
    height: auto;
    max-height: none;
    overflow-y: visible;
  }

  .run-day-card {
    padding: 12px;
  }

  .run-icon {
    font-size: 16px;
  }

  .map-container {
    margin: 10px 0;
  }

  .map-container iframe {
    height: 300px;
    max-height: 300px;
  }
}

@media (max-width: 500px) {
  .club-profile {
    padding: 15px;
  }

  .club-title {
    font-size: 2em;
  }

  .club-subtitle {
    font-size: 1em;
  }

  .club-image {
    aspect-ratio: 4/3;
    width: 100%;
  }

  .club-images {
    display: flex;
    flex-direction: column;

  }

  .club-main, .club-sidebar, .club-feeds, .club-map {
    padding: 15px;
    margin-bottom: 15px;
  }

  .map-container iframe {
    height: 250px;
    max-height: 250px;
  }

  .view-route-button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .join-button {
    width: 100%;
    padding: 8px 12px;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .join-button svg {
    width: 16px;
    height: 16px;
  }

  .join-button span {
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .club-image {
    max-height: 180px;
  }
}

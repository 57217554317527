.blog-page-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: left; /* Ensure the container aligns all text to the left */
  }
  
  .blog-page-container h1 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    text-align: left; /* Ensure the main heading is left-aligned */
  }

  .blog-page-container h2 {
    font-size: 2.5em;
    margin-bottom: 0.5em;
    text-align: left; /* Ensure the main heading is left-aligned */
  }
  
  .blog-page-container p {
    font-size: 1.2em;
    margin-bottom: 1em;
    text-align: left; /* Ensure all paragraphs are left-aligned */
  }
  
  .blog-page-container h2,
  .blog-page-container h3,
  .blog-page-container h4,
  .blog-page-container h5,
  .blog-page-container h6 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    text-align: left; /* Ensure all subheadings are left-aligned */
  }
  
  .blog-page-container ul,
  .blog-page-container ol {
    margin-left: 1.5em;
    margin-bottom: 1em;
    text-align: left; /* Ensure lists are left-aligned */
  }
  
  .blog-page-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .blog-page-container a:hover {
    text-decoration: underline;
  }
  
.category-detail {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.category-header {
  margin-bottom: 2rem;
}

.category-header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #333;
  margin: 1rem 0;
}

.category-detail .card-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 2rem;
  width: 100%;
}

.category-detail .card-section > * {
  width: 100%;
  height: 100%;
}

.no-results {
  text-align: center;
  padding: 3rem;
  background: #f8f9fa;
  border-radius: 8px;
  grid-column: 1 / -1;
}

.no-results p {
  font-size: 1.2rem;
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .category-detail {
    padding: 1rem;
  }

  .category-header h1 {
    font-size: 2rem;
  }

  .category-detail .card-section {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
  
.claim-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  color: var(--navbar-text);
  padding: 0.6rem 1.2rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  background: transparent;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
}

.claim-button:hover {
  color: var(--navbar-hover);
  background-color: var(--button-highlight);
  transform: translateY(-2px);
  border: 2px;
}

@media (max-width: 768px) {
  .claim-button {
    font-size: 0.9rem;
    padding: 0.5rem;
    width: 100%;
    text-align: center;
  }
}
/* Root Variables for Colors and Styles */
:root {
  --navbar-bg: #FFFFFF;
  --navbar-border: #E6F3FF;
  --navbar-shadow: rgba(0, 0, 0, 0.1);
  --navbar-text: #1A3A5A;
  --navbar-hover: #4A90E2;
}

/* Container for Dropdown and Text */
.city-selector-dropdown-container {
  position: relative;
  display: inline-block;
  width: auto;
  margin-left: 160px;
}

/* Text to Open Dropdown */
.city-selector-text {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: #2f2fd3; /* Match the Resy blue color */
  padding: 8px 12px;
  transition: color 0.3s ease;
  text-decoration: none; /* Ensure no underline by default */
}

.city-selector-text:hover {
  color: #b32020; /* Slightly darker for hover */
  text-decoration: none; /* Ensure no underline on hover */
}

/* Remove underline when the text is focused, clicked, or active */
.city-selector-text:focus,
.city-selector-text:active,
.city-selector-text:visited,
.city-selector-text:hover {
  text-decoration: none !important;
  outline: none !important; /* Remove focus outline */
}

/* Dropdown Icon */
.dropdown-icon {
  font-size: 20px;
  margin-left: 5px;
  transition: transform 0.2s ease-in-out;
  color: #d32f2f;
}

.city-selector-text:hover .dropdown-icon {
  transform: rotate(180deg);
}

/* Dropdown Menu */
.city-selector-dropdown {
  position: absolute;
  top: 120%;
  left: 0;
  width: 200px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* City List Container */
.city-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* City Option */
.city-option {
  padding: 8px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background 0.3s, color 0.2s;
}

.city-option:hover {
  background: #f0f0f0;
}

.city-option.selected {
  font-weight: bold;
  color: #d32f2f;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles for Mobile */
@media (max-width: 768px) {
  .city-selector-dropdown-container {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0px;

  }

  .city-selector-text {
    font-size: 16px; /* Adjusted size for mobile screens */
    padding: 6px 10px; /* Adjusted padding for mobile screens */
  }

  .dropdown-icon {
    font-size: 18px; /* Adjusted icon size for mobile */
    margin-left: 3px; /* Smaller margin for mobile */
  }

  .city-selector-dropdown {
    width: 180px; /* Adjust width for mobile dropdown */
    padding: 8px; /* Adjust padding for better fit on smaller screens */
  }

  .city-option {
    font-size: 14px; /* Adjust font size for mobile dropdown options */
    padding: 6px; /* Adjust padding for better fit */
  }
}

.map-container {
    border-radius: 8px;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .map-container iframe {
    width: 100%;
    border: 0;
    border-radius: 8px;
  }
  
  @media (max-width: 500px) {
    .map-container {
      margin: 10px 0;  /* Reduce vertical margins */
    }
  
    .map-container iframe {
      height: 300px;  /* Reduce height for smaller screens */
      max-height: 300px;
    }
  }
.run-details-sharing {
    margin: 15px 0;
  }
  
  .copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 16px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    /* background: linear-gradient(135deg, #4A90E2 0%, #5CB3FF 100%); */
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .copy-button:hover {
    /* background: linear-gradient(135deg, #3A80D2 0%, #4CA3EF 100%); */
    transform: translateY(-2px);
  }
  
  .copy-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 5px rgba(74, 144, 226, 0.4);
  }
  
  .copy-button svg {
    width: 16px;
    height: 16px;
    transition: all 0.3s ease;
  }
  
  .copy-button:hover svg {
    transform: scale(1.1);
  }
  
  .copy-button.copied {
    background: linear-gradient(135deg, #2ECC71 0%, #27AE60 100%);
    box-shadow: 0 2px 10px rgba(46, 204, 113, 0.3);
  }
  
  .copy-button.copied:hover {
    background: linear-gradient(135deg, #27AE60 0%, #229954 100%);
    box-shadow: 0 4px 15px rgba(46, 204, 113, 0.4);
  }
  
  @media (max-width: 768px) {
    .copy-button {
      font-size: 12px;
      padding: 8px 14px;
    }
  
    .copy-button svg {
      width: 14px;
      height: 14px;
    }
  }
.categories-container {
  width: 100%;
  overflow-x: auto;
  padding: 1rem 0;
  background-color: #ffffff; /* Changed to white background */
}

.categories {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem; /* Increased horizontal padding */
  min-width: 100%;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  margin: 0 0.5rem;
  flex: 1;
  min-width: 90px;
  border-radius: 12px;
  transition: all 0.3s ease;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  cursor: pointer; /* Add this line to show the hand cursor on hover */
}

.category:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.15);
}

.category p {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #333;
  font-weight: 500;
  text-align: center;
}

.icon {
  color: #4A90E2;
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

.category:hover .icon {
  transform: scale(1.1);
}

/* Custom scrollbar for Webkit browsers */
.categories-container::-webkit-scrollbar {
  height: 6px;
}

.categories-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.categories-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.categories-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .categories {
    justify-content: flex-start;
    min-width: max-content;
    padding: 0 1rem; /* Reduced padding for mobile */
  }

  .category {
    flex: 0 0 auto;
    margin: 0 0.35rem; /* Slightly reduced margin for mobile */
    min-width: 80px; /* Reduced min-width for mobile */
  }

  .category p {
    font-size: 0.7rem;
  }

  .icon {
    font-size: 1.2rem;
  }
}
.day-chip-container {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .day-chip {
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
  }
  
  .day-monday {
    background-color: #e6f2ff;
    color: #1e40af;
    font-size: 14px;
  }
  
  .day-tuesday {
    background-color: #e6ffea;
    color: #166534;
    font-size: 14px;
  }
  
  .day-wednesday {
    background-color: #fefce8;
    color: #854d0e;
    font-size: 14px;
  }
  
  .day-thursday {
    background-color: #f3e8ff;
    color: #6b21a8;
    font-size: 14px;
  }
  
  .day-friday {
    background-color: #fce7f3;
    color: #9d174d;
    font-size: 14px;
  }
  
  .day-saturday {
    background-color: #fee2e2;
    color: #991b1b;
    font-size: 14px;
  }
  
  .day-sunday {
    background-color: #fff7ed;
    color: #9a3412;
    font-size: 14px;
  }
.blogs-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Roboto', sans-serif;
  border-radius: 10px;
}

.blogs-header {
  text-align: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e0e0e0;
}

.blogs-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.blogs-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.featured-blog {
  grid-column: 1 / -1;
}

.featured-blog, .blog-card {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
}

.featured-blog:hover, .blog-card:hover {
  transform: translateY(-5px);
}

.blog-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.blog-content {
  flex: 1;
}

.blog-title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  text-decoration: none;
}

.blog-description {
  font-size: 1rem;
  color: #34495e;
  margin-bottom: 0.5rem;
}

.blog-date {
  font-size: 0.9rem;
  color: #7f8c8d;
  margin-bottom: 1rem;
}

.blog-image-container {
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.blog-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.featured-blog .blog-image-container {
  padding-top: 40%; /* Slightly different aspect ratio for featured blog */
}

.other-blogs {
  display: contents;
}

@media (max-width: 768px) {
  .blogs-container {
    padding: 1rem;
  }

  .blogs-title {
    font-size: 2rem;
  }

  .blogs-content {
    grid-template-columns: 1fr;
  }

  .blog-image-container {
    padding-top: 66.67%; /* 3:2 aspect ratio for mobile */
  }
}
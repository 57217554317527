/* Badge.css */

.badge {
  display: inline-flex;
  align-items: center;
  background-color: #1e3a8a; /* Dark blue background for all badges */
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 20px; /* Increased border radius for nicer rounded corners */
  margin-top: 20px;
  margin-right: 8px;
  transition: all 0.3s ease;
}

.badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.badge-icon {
  display: inline-flex;
  margin-right: 6px;
}

.badge-text {
  line-height: 1;
}

/* Specific styles for each badge type */
.badge-weekly .badge-icon {
  color: #60a5fa; /* Light blue for weekly icon */
}

.badge-paid .badge-icon {
  color: #34d399; /* Light green for paid icon */
}

.badge-free .badge-icon {
  color: #a78bfa; /* Light purple for free icon */
}

.badge-social .badge-icon {
  color: #fcd34d; /* Light yellow for social icon */
}

.badge-competitive .badge-icon {
  color: #f87171; /* Light red for competitive icon */
}

/* Responsive design */
@media (max-width: 768px) {
  .badge {
    font-size: 12px;
    padding: 4px 8px;
  }

  .badge-icon svg {
    width: 14px;
    height: 14px;
  }
}